
import { mapState } from 'vuex'
import CategoryBackgroundMediaSection from '~/components/organisms/CategoryBackgroundMediaSection'
import CategoryOfferingsSection from '~/components/organisms/SportblxOfferingsSection'
import CategoryInvestmentsSection from '~/components/organisms/CategoryInvestmentsSection'
import CategoryTestimonialsSection from '~/components/organisms/CategoryTestimonialsSection'
import CategoryFAQSection from '~/components/organisms/CategoryFAQSection'
import CategoryPublicationLogos from '~/components/organisms/CategoryPublicationLogos'

export default {
    name: 'CategoryPlacePage',

    components: {
      CategoryBackgroundMediaSection,
      CategoryInvestmentsSection,
      CategoryOfferingsSection,
      CategoryTestimonialsSection,
      CategoryPublicationLogos,
      CategoryFAQSection,
    },

    head() {
      return {
        title: `Annestes Farm - Max Player`,
      }
    },

    async fetch () {
      await this.$store.dispatch('sportblx/getAppSettings')
    },

    data() {
      return {
      }
    },

    computed: {
      sectionBackgroundMedia() {
        return this.findSection('SECTION_BACKGROUND_MEDIA')
      },
      sectionOfferingInfo () {
        return this.findSection('SECTION_OFFERING_INFO')
      },
      sectionInvestingForAll() {
        return this.findSection('SECTION_INVESTING_FOR_ALL')
      },
      sectionIndustryRecords() {
        return this.findSection('SECTION_INDUSTRY_RECORDS')
      },
      sectionIndustryRecordsCarousel() {
        return this.findSection('SECTION_INDUSTRY_RECORDS_CAROUSEL')
      },
      sectionCustomInvestment() {
        return this.findSection('SECTION_CUSTOM_INVESTMENT')
      },
      sectionTestimonialsCarousel() {
        return this.findSection('SECTION_TESTIMONIALS_CAROUSEL')
      },
      sectionAsFeaturedIn() {
        return this.findSection('SECTION_AS_FEATURED_IN')
      },
      sectionHowItWorks() {
        return this.findSection('SECTION_HOW_IT_WORKS')
      },
      ...mapState('sportblx', ['maxPlayerSetting']),
    },

    methods: {
      findSection(sectionName) {
        if (this.maxPlayerSetting && this.maxPlayerSetting.cardsCarousel && this.maxPlayerSetting.cardsCarousel.length) {
          const { cardsCarousel } = this.maxPlayerSetting

          for (const i in cardsCarousel) {
            const card = cardsCarousel[i]

            if (!card.title || !card.title.input || card.title.hidden || !card.enabled) {
              continue
            }

            if (card.title.input === sectionName) {
              return card
            }
          }
        }

        return {}
      }
    }

  }
